import * as React from "react";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";

const HackathonJudging = () => {
  const { t } = useTranslation();
  return (
    <>
      <section id="judging" className="relative pt-80 pb-128">
        <div className="space-custom"></div>
        <div className="container relative z-20">
          <h2 className="heading-2">
            <Trans>Judges</Trans>
          </h2>
          <div className="crew-list">
            <div className="crew-item min-h-340">
              <img
                srcSet="/images/crew/caro2x.webp 2x, /images/crew/caro.webp 1x"
                src="/images/crew/caro.webp"
                width="246"
                height="246"
                alt="caro"
                className="flex align-center mx-auto"
              />
              <p className="text-24 font-semibold">
                <Trans>Caro Rossi</Trans>
              </p>
              <p className="leading-none text-menu-white">
                <Trans>CMO at dcSpark, Focus on </Trans>
              </p>
              <p className="leading-none text-menu-white">
                <Trans>Pitch &#38; General Setup</Trans>
              </p>
            </div>
            <div className="crew-item min-h-340">
              <img
                srcSet="/images/crew/nico2x.webp 2x, /images/crew/nico.webp 1x"
                src="/images/crew/nico.webp"
                width="246"
                height="246"
                alt="wyatt"
                className="flex align-center mx-auto"
              />
              <p className="text-24 font-semibold">
                <Trans>Nicolas Arqueros</Trans>
              </p>
              <p className="leading-none text-menu-white">
                <Trans>Co-founder and CEO at </Trans>
              </p>
              <p className="leading-none text-menu-white">
                <Trans>dcSpark, Focus on Tech</Trans>
              </p>
            </div>
            <div className="crew-item min-h-340">
              <img
                srcSet="/images/crew/wyatt2x.webp 2x, /images/crew/wyatt2x.webp 1x"
                src="/images/crew/wyatt2x.webp"
                width="246"
                height="246"
                alt="nico"
                className="flex align-center mx-auto"
              />
              <p className="text-24 font-semibold">
                <Trans>Wyatt Khosrowshahi</Trans>
              </p>
              <p className="leading-none text-menu-white">
                <Trans>Investor at Shima Capital</Trans>
              </p>
            </div>
            <div className="crew-item min-h-340">
              <img
                srcSet="/images/crew/karyna2x.webp 2x, /images/crew/karyna2x.webp 1x"
                src="/images/crew/karyna2x.webp"
                width="246"
                height="246"
                alt="karyna"
                className="flex align-center mx-auto"
              />
              <p className="text-24 font-semibold">
                <Trans>Karyna Sovich</Trans>
              </p>
              <p className="leading-none text-menu-white">
                <Trans>Events and Sponsorship Lead at Binance</Trans>
              </p>
            </div>
            <div className="crew-item min-h-340">
              <img
                src="/images/crew/bullish2x.png"
                width="246"
                height="246"
                alt="bullish"
                className="flex align-center mx-auto"
              />
              <p className="text-24 font-semibold">
                <Trans>Bullish Dumpling</Trans>
              </p>
              <p className="leading-none text-menu-white">
                <Trans>Media Partner from the community</Trans>
              </p>
            </div>
            <div className="crew-item min-h-340">
              <img
                srcSet="/images/crew/galen-law-kun2x.png 2x, /images/crew/galen-law-kun2x.png 1x"
                src="/images/crew/galen-law-kun2x.png"
                width="246"
                height="246"
                alt="bullish"
                className="flex align-center mx-auto"
              />
              <p className="text-24 font-semibold">
                <Trans>Galen Law-Kun</Trans>
              </p>
              <p className="leading-none text-menu-white">
                <Trans>Founder at Double Peak Group</Trans>
              </p>
            </div>
          </div>
        </div>

        <div className="container h-full absolute top-0 left-0 right-0 bottom-0">
          <div className="layout-grid">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </section>

      <section id="features" className="relative">
        <div className="space-custom"></div>
        <div className="custom-header relative">
          <h1 className="heading-rules absolute">RULES</h1>
          <h2 className="heading-2 mb-0 absolute">
            <Trans>Judging Criteria</Trans>
          </h2>
        </div>
        <div className="container mt-64">
          <ul className="-m-10 flex flex-wrap justify-center">
            <li className="card-item-3 card card-b">
              <div className="block box-item-1 bg">
                <div className="pt-32 pb-40 px-16">
                  <p className="text-32 font-semibold">Rule 1</p>
                  <p className="pt-10">You need to start coding at day 1</p>
                </div>
              </div>
            </li>
            <li className="card-item-3 card card-b animation-delay-300">
              <div className="block box-item-1">
                <div className="pt-32 pb-40 px-16">
                  <p className="text-32 font-semibold">Rule 2</p>
                  <p className="pt-10">You need to make publicly available a github repo with the code</p>
                </div>
              </div>
            </li>
            <li className="card-item-3 card card-b animation-delay-600">
              <div className="block box-item-1">
                <div className="pt-32 pb-40 px-16">
                  <p className="text-32 font-semibold">Rule 3</p>
                  <p className="pt-10">You can use other publicly available projects to bootstrap</p>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div className="container relative z-30">
          <ul className="mt-32 -m-10 flex flex-wrap w-auto sm:w-[522px] md:w-[auto] ml-auto mr-auto justify-center sm:justify-start md:justify-center">
            <li className="card-item-5 card card-b">
              <div className="card-custom-bg">
                <div className="h-full flex items-center justify-center flex-col p-32 text-white">
                  <img
                    srcSet="/images/icons/hackathon/technical.webp 2x, /images/icons/hackathon/technical.webp 1x"
                    src="/images/icons/hackathon/technical.webp"
                    width="64"
                    height="64"
                    alt="technical"
                  />
                  <p className="pt-10">Technical Implementation</p>
                </div>
              </div>
            </li>
            <li className="card-item-5 card card-b animation-delay-300">
              <div className="card-custom-bg">
                <div className="h-full flex items-center justify-center flex-col p-32 text-white">
                  <img
                    srcSet="/images/icons/hackathon/design2x.webp 2x, /images/icons/hackathon/design.webp 1x"
                    src="/images/icons/hackathon/design.webp"
                    width="64"
                    height="64"
                    alt="design"
                  />
                  <p className="pt-10">Design</p>
                </div>
              </div>
            </li>
            <li className="card-item-5 card card-b animation-delay-600">
              <div className="card-custom-bg">
                <div className="h-full flex items-center justify-center flex-col p-32 text-white">
                  <img
                    srcSet="/images/icons/hackathon/impact2x.webp 2x, /images/icons/hackathon/impact.webp 1x"
                    src="/images/icons/hackathon/impact.webp"
                    width="64"
                    height="64"
                    alt="impact"
                  />
                  <p className="pt-10">Potential</p>
                  <p>Impact</p>
                </div>
              </div>
            </li>
            <li className="card-item-5 card card-b animation-delay-600">
              <div className="card-custom-bg">
                <div className="h-full flex items-center justify-center flex-col p-32 text-white">
                  <img
                    srcSet="/images/icons/hackathon/development2x.webp 2x, /images/icons/hackathon/development.webp 1x"
                    src="/images/icons/hackathon/development.webp"
                    width="64"
                    height="64"
                    alt="development"
                  />
                  <p className="pt-10">Development</p>
                </div>
              </div>
            </li>
            <li className="card-item-5 card card-b animation-delay-600">
              <div className="card-custom-bg">
                <div className="h-full flex items-center justify-center flex-col p-32 text-white">
                  <img
                    srcSet="/images/icons/hackathon/pitch2x.webp 2x, /images/icons/hackathon/pitch.webp 1x"
                    src="/images/icons/hackathon/pitch.webp"
                    width="64"
                    height="64"
                    alt="pitch"
                  />
                  <p className="pt-10">Pitch</p>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div className="container h-full absolute top-0 left-0 right-0 bottom-0 z-0">
          <div className="layout-grid">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </section>
    </>
  );
};

export default HackathonJudging;
