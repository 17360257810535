import * as React from "react";
import { graphql } from "gatsby";
import { I18nextContext, useTranslation } from "gatsby-plugin-react-i18next";
import FooterSection from "../components/FooterSection";
import MilkHeader from "../components/MilkHeader";
import PageMeta from "../components/PageMeta";
import HackathonLanding from "../components/HackathonLanding";
import HackathonChallenge from "../components/HackathonChallenge";
import HackathonJudging from "../components/HackathonJudging";
import HackathonDetails from "../components/HackathonDetails";

const AccelerationPage = () => {
  const { t } = useTranslation();
  const langContext = React.useContext(I18nextContext);
  return (
    <>
      <main>
        <PageMeta
          title={t("Hackathon • Milkomeda")}
          description={t(
            "Milkomeda brings the most used smart-contract programming language across blockchains. Develop once, deploy everywhere."
          )}
          locale={t("en_US")}
          language={langContext.language}
        />
        <MilkHeader />
        <div>
          <HackathonLanding />
          <HackathonChallenge />
          <HackathonJudging />
          <HackathonDetails />
        </div>
      </main>
      <FooterSection
        footerImage={
          "/images/icons/hackathon/star-swirl-bottom2x.webp 2x, /images/icons/hackathon/star-swirl-bottom2x.webp 1x"
        }
      />
    </>
  );
};

export default AccelerationPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
