import * as React from "react";
import { Trans } from "gatsby-plugin-react-i18next";
// import HackathonSignup from "./HackathonSignup";

const AccelerationLanding = () => {
  return (
    <>
      <section className="w-full relative z-20 -mb-10" id="hackathon-landing">
        <div className="absolute w-full h-full top-0 left-0 -z-10 hero-video">
          <div className="bg-video-hackathon -z-20">
            <video className="image-cover" playsInline autoPlay muted loop>
              <source src="/video-hackathon.mp4" type="video/mp4" />
            </video>
          </div>
        </div>
        <div className="container h-full absolute top-0 left-0 right-0 bottom-0">
          <div className="layout-grid">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <div className="container pt-144 2xl:pt-160 sc-up-mb z-20 relative">
          <div className="text-center animate-from-bottom pb-40 md:pb-60">
            <p>Official dates: 05.09.2022 - 04.10.2022</p>
            <h1 className="heading-3 heading-1-2 ml-100">
              <Trans>Milkomeda DAO Hackathon</Trans>
            </h1>
            <p className="align-center mt-24 mb-24 mr-140 ml-140 text-menu-white font-normal md:text-20 intro-text">
              <Trans>
                Milkomeda Foundation invites all builders around the world to come together and
                create the tools and solutions for decentralized governance in Cardano.
              </Trans>
            </p>
            <a
              className="btn-addNetwork mt-16"
              href="https://github.com/dcSpark/milkomeda-dao-hackathon"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Trans>DAO Hackathon Repository</Trans>
            </a>
          </div>
        </div>
        {/* <HackathonSignup /> */}
        {/* Obal after hero video */}
        <div className="w-full relative">
          <img
            className="w-full relative z-10"
            srcSet="/images/oval_darkblue_BG2x.webp 2x, /images/oval_darkblue_BG2x.webp 1x"
            src="/images/oval_darkblue_BG2x.webp"
            width="1600"
            height="235"
            alt="oval-top"
          />
          <div className="container h-full absolute top-0 left-0 right-0 bottom-0 z-[11px]">
            <div className="layout-grid">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </section>

      <section className="relative bg-[#030911]">
        <div className="section-custom-top">
          <div className="container relative z-10">
            <div className="mx-auto w-full max-w-[516px] relative z-20">
              <p className="text-24 mt-32 font-semibold">
                <Trans>Join forces to build the future of DAOs on Cardano.</Trans>
              </p>
              <p className="mt-20 text-menu-white leading-normal">
                <Trans>
                  With Milkomeda, DAOs can be launched using the mature EVM-based ecosystem. We
                  invite all builders around the world to come together and create the tools and
                  solutions for decentralized governance in Cardano.
                </Trans>
              </p>
              <p className="text-24 mt-32 font-semibold">
                <Trans>
                  If you are web3 developer and would like to create novel solutions for DAOs apply
                  for our 2-week hackathon.
                </Trans>
              </p>
            </div>
          </div>
        </div>

        <div>
          <div className="container z-20">
            <ul className="section-custom-top flex flex-wrap justify-center relative z-20">
              <li className="card-item card card-b">
                <div className="bg-blue-bg-dark py-36 px-24">
                  <div className="w-120 h-120 mx-auto">
                    <img
                      className="image-sd"
                      srcSet="/images/icons/hackathon/teams2x.webp 2x, /images/icons/hackathon/teams.webp 1x"
                      src="/images/icons/hackathon/teams.webp"
                      width="120"
                      height="120"
                      alt="teams"
                    />
                  </div>
                  <div className="mt-20 mb-auto">
                    <p className="text-24 font-semibold">
                      <Trans>Teams</Trans>
                    </p>
                    <p className="mt-10 text-menu-white leading-normal px-45">
                      <Trans>
                        You can join a team by yourself, or we can help you find a team.
                      </Trans>
                    </p>
                  </div>
                </div>
              </li>
              <li className="card-item card card-b animation-delay-300">
                <div className="bg-blue-bg-dark py-36 px-24">
                  <div className="w-120 h-120 mx-auto">
                    <img
                      className="image-sd"
                      srcSet="/images/icons/hackathon/profile2x.webp 2x, /images/icons/hackathon/profile.webp 1x"
                      src="/images/icons/hackathon/profile.webp"
                      width="120"
                      height="120"
                      alt="profile"
                    />
                  </div>
                  <div className="mt-20 mb-auto">
                    <p className="text-24 font-semibold">
                      <Trans>Profile</Trans>
                    </p>
                    <p className="mt-10 text-menu-white leading-normal">
                      <Trans>Solidity Developers (experienced and beginners)</Trans>
                    </p>
                  </div>
                </div>
              </li>
              <li className="card-item card card-b animation-delay-600">
                <div className="bg-blue-bg-dark py-36 px-24">
                  <div className="w-120 h-120 mx-auto">
                    <img
                      className="image-sd"
                      srcSet="/images/icons/hackathon/time2x.webp 2x, /images/icons/hackathon/time.webp 1x"
                      src="/images/icons/hackathon/time.webp"
                      width="120"
                      height="120"
                      alt="time"
                    />
                  </div>
                  <div className="mt-20 mb-auto">
                    <p className="text-24 font-semibold">
                      <Trans>Time</Trans>
                    </p>
                    <p className="mt-10 text-menu-white leading-normal">
                      <Trans>2 weeks</Trans>
                    </p>
                  </div>
                </div>
              </li>
              <li className="card-item card card-b animation-delay-700">
                <div className="bg-blue-bg-dark py-36 px-24">
                  <div className="w-120 h-120 mx-auto">
                    <img
                      className="image-sd"
                      srcSet="/images/icons/hackathon/format2x.png 2x, /images/icons/hackathon/format2x.png 1x"
                      src="/images/icons/hackathon/format.png"
                      width="120"
                      height="120"
                      alt="format"
                    />
                  </div>
                  <div className="mt-20 mb-auto">
                    <p className="text-24 font-semibold">
                      <Trans>Format</Trans>
                    </p>
                    <p className="mt-10 text-menu-white leading-normal">
                      <Trans>100% via online</Trans>
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="container h-full absolute top-0 left-0 right-0 bottom-0">
          <div className="layout-grid">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </section>
    </>
  );
};

export default AccelerationLanding;
