import * as React from "react";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";
// import HackathonSignup from "./HackathonSignup";

const AccelerationLanding = () => {
  const { t } = useTranslation();
  return (
    <>
      {/* Obal after hero before Challenges */}
      <div className="w-full relative">
        <img
          className="w-full relative z-10"
          srcSet="/images/oval_darkblue_bottom2x.webp 2x, /images/oval_darkblue_bottom2x.webp 1x"
          src="/images/oval_BG_down.webp"
          width="1600"
          height="235"
          alt="oval-top"
        />
        <div className="container h-full absolute top-0 left-0 right-0 bottom-0 z-30">
          <div className="layout-grid">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
      <section className="relative">
        <div className="pt-24">
          <div className="container relative z-10">
            <div className="mx-auto w-full max-w-[516px] relative z-20">
              <h2 className="heading-2">
                <Trans>Challenges</Trans>
              </h2>
            </div>
          </div>
        </div>
        <div className="container z-20">
          <ul className="section-custom-top flex flex-wrap justify-center relative z-20">
            <li className="card-item card card-b pb-4 lg:pb-30">
              <div className="box-item bg-black-bg-dark py-36 px-24 custom-card">
                <div className="w-200 h-150 mx-auto">
                  <img
                    className="image-sd"
                    srcSet="/images/icons/hackathon/ui-daos2x.webp 2x, /images/icons/hackathon/ui-daos2x.webp 1x"
                    src="/images/icons/hackathon/ui-daos2x.webp"
                    width="300"
                    height="207"
                    alt="UI for DAOs"
                  />
                </div>
                <div className="mt-20 mb-auto">
                  <p className="text-28 font-medium">
                    <Trans>UI for DAOs</Trans>
                  </p>
                  <p className="mt-10 text-menu-white leading-normal px-45">
                    <Trans>User interface for DAOs</Trans>
                  </p>
                </div>
              </div>
            </li>
            <li className="card-item card card-b pb-4 lg:pb-30 animation-delay-300">
              <div className="box-item bg-black-bg-dark py-36 px-24 custom-card">
                <div className="w-200 h-150 mx-auto">
                  <img
                    className="image-sd"
                    srcSet="/images/icons/hackathon/tooling2x.webp 2x, /images/icons/hackathon/tooling2x.webp 1x"
                    src="/images/icons/hackathon/tooling2x.webp"
                    width="300"
                    height="207"
                    alt="Tooling for DAOs"
                  />
                </div>
                <div className="mt-20 mb-auto">
                  <p className="text-28 font-medium">
                    <Trans>Tooling for DAOs</Trans>
                  </p>
                  <p className="mt-10 text-menu-white leading-normal">
                    <Trans>Manage and control your DAOs</Trans>
                  </p>
                </div>
              </div>
            </li>
            <li className="card-item card card-b pb-4 lg:pb-30 animation-delay-600">
              <div className="box-item bg-black-bg-dark py-36 px-24 custom-card">
                <div className="w-200 h-150 mx-auto">
                  <img
                    className="image-sd"
                    srcSet="/images/icons/hackathon/social-dao2x.webp 2x, /images/icons/hackathon/social-dao2x.webp 1x"
                    src="/images/icons/hackathon/social-dao2x.webp"
                    width="300"
                    height="207"
                    alt="Social DAOs"
                  />
                </div>
                <div className="mt-20 mb-auto">
                  <p className="text-28 font-medium">
                    <Trans>Social DAOs</Trans>
                  </p>
                  <p className="mt-10 text-menu-white leading-normal">
                    <Trans>Protocol Governance DAO and Exclusive Club DAOs (Exclusive Access to stuff).</Trans>
                  </p>
                </div>
              </div>
            </li>
            <li className="card-item card card-b pb-4 lg:pb-30 animation-delay-700">
              <div className="box-item bg-black-bg-dark py-36 px-24 custom-card">
                <div className="w-200 h-150 mx-auto">
                  <img
                    className="image-sd"
                    srcSet="/images/icons/hackathon/defi-daos2x.webp 2x, /images/icons/hackathon/defi-daos2x.webp 1x"
                    src="/images/icons/hackathon/defi-daos2x.webp"
                    width="300"
                    height="207"
                    alt="DeFi DAOs"
                  />
                </div>
                <div className="mt-20 mb-auto">
                  <p className="text-28 font-medium">
                    <Trans>DeFi DAOs</Trans>
                  </p>
                  <p className="mt-10 text-menu-white leading-normal">
                    <Trans>Indexing / Investing</Trans>
                  </p>
                </div>
              </div>
            </li>
            <li className="card-item card card-b pb-4 lg:pb-30 animation-delay-700">
              <div className="box-item bg-black-bg-dark py-36 px-24 custom-card">
                <div className="w-200 h-150 mx-auto">
                  <img
                    className="image-sd"
                    srcSet="/images/icons/hackathon/bootstrapping2x.webp 2x, /images/icons/hackathon/bootstrapping2x.webp 1x"
                    src="/images/icons/hackathon/bootstrapping2x.webp"
                    width="300"
                    height="207"
                    alt="Bootstrapping DAOs"
                  />
                </div>
                <div className="mt-20 mb-auto">
                  <p className="text-28 font-medium">
                    <Trans>Bootstrapping DAOs</Trans>
                  </p>
                  <p className="mt-10 text-menu-white leading-normal">
                    <Trans>Airdrops tools for DAOs</Trans>
                  </p>
                </div>
              </div>
            </li>
            <li className="card-item card card-b pb-4 lg:pb-30 animation-delay-700">
              <div className="box-item bg-black-bg-dark py-36 px-24 custom-card">
                <div className="w-200 h-150 mx-auto">
                  <img
                    className="image-sd"
                    srcSet="/images/icons/hackathon/other-ideas2x.webp 2x, /images/icons/hackathon/other-ideas2x.webp 1x"
                    src="/images/icons/hackathon/other-ideas2x.webp"
                    width="300"
                    height="207"
                    alt="Other cool ideas that you may have!"
                  />
                </div>
                <div className="mt-20 mb-auto">
                  <p className="text-28 font-medium">
                    <Trans>Other cool ideas that you may have!</Trans>
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div className="container h-full absolute top-0 left-0 right-0 bottom-0">
          <div className="layout-grid">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </section>

      <section className="relative 2xl:-mt-110">
        <div className="prize-section lg:pt-208">
          <div className="absolute w-full h-680 top-0 left-0 -z-10 prizes-bg">
            <img
              className="w-full h-full object-cover cover-mobile"
              srcSet="/images/icons/hackathon/star-galaxy-bg2x.png 2x, /images/icons/hackathon/star-galaxy-bg2x.png 1x"
              src="/images/icons/hackathon/star-galaxy-bg2x.png"
              width="1600"
              height="680"
              alt="page-bg"
            />
          </div>
          <div className="container sc-up-mb z-20 relative">
            <div className="text-center animate-from-bottom">
              <h1 className="heading-3 heading-1-2 ml-100 mb-48">
                <Trans>$45K IN TOTAL PRIZES</Trans>
              </h1>
            </div>
          </div>
          <div className="space-custom"></div>
          <div className="container z-20">
            <ul className="flex flex-wrap justify-center relative z-20 max-xl:mb-40 2xl:mt-40 2xl:mb-64 m-big-screen">
              <li className="card-item-6 card card-b box-prize-1">
                <div className="box-item-2 py-16">
                  <div className="h-156">
                    <p className="text-16 mt-30 font-medium">
                      <Trans>1st place</Trans>
                    </p>
                    <p className="leading-normal px-45 text-48 heading-1">
                      <Trans>$12K</Trans>
                    </p>
                  </div>
                </div>
              </li>
              <li className="card-item-6 card card-b animation-delay-300 box-prize-2">
                <div className="box-item-2 py-16">
                  <div className="mt-30">
                    <p className="text-16 font-medium">
                      <Trans>2nd place</Trans>
                    </p>
                    <p className="text-menu-white font-bold	mt-10 leading-normal text-40">
                      <Trans>$10K</Trans>
                    </p>
                  </div>
                </div>
              </li>
              <li className="card-item-6 card card-b animation-delay-600 box-prize-3">
                <div className="box-item-2 py-16">
                  <div className="mt-30">
                    <p className="text-16 font-medium">
                      <Trans>3rd place</Trans>
                    </p>
                    <p className="text-menu-white font-bold	mt-10 leading-normal text-40">
                      <Trans>$8K</Trans>
                    </p>
                  </div>
                </div>
              </li>
              <li className="card-item-6 card card-b animation-delay-700 box-prize-4">
                <div className="box-item-2 py-16">
                  <div className="mt-30">
                    <p className="text-16 font-medium">
                      <Trans>Best idea</Trans>
                    </p>
                    <p className="text-menu-white font-bold	mt-10 leading-normal text-40">
                      <Trans>$5K</Trans>
                    </p>
                  </div>
                </div>
              </li>
              <li className="card-item-6 card card-b animation-delay-700 box-prize-4">
                <div className="box-item-2 py-16">
                  <div className="mt-30">
                    <p className="text-16 font-medium">
                      <Trans>Closer to launch</Trans>
                    </p>
                    <p className="text-menu-white font-bold	mt-10 leading-normal text-40">
                      <Trans>$5K</Trans>
                    </p>
                  </div>
                </div>
              </li>
              <li className="card-item-6 card card-b animation-delay-700 box-prize-4">
                <div className="box-item-2 py-16">
                  <div className="mt-30">
                    <p className="text-16 font-medium">
                      <Trans>Best pitch</Trans>
                    </p>
                    <p className="text-menu-white font-bold	mt-10 leading-normal text-40">
                      <Trans>$5K</Trans>
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="container h-full absolute top-0 left-0 right-0 bottom-0">
          <div className="layout-grid">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </section>
      <section className="bg-prizes-form">{/* <HackathonSignup /> */}</section>
    </>
  );
};

export default AccelerationLanding;
